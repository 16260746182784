// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React from 'react'
import { css, jsx } from "@emotion/core";

const sectionStyle = css`
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

function Title({text}) {
    return (<h2>{text}</h2>)
}


export default function Section({title, children, reverse}) {
    const content = [
        (<Title text={title}/>), 
        (<div css={css`width: 25rem; font-size: 16px; ${reverse ? "text-align: left" : "text-align: right"}`}>{children}</div>)
    ]

    return (
        <div css={sectionStyle}>
            {reverse ? content : content.reverse()}
        </div>
    )

}
