// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import React from 'react'
import { css, jsx } from "@emotion/core";

export default function Logo() {
    return (
        <div css={css`
            font-size: 2vmin;
        `}>
        <pre>
            {`
$$\\                   $$\\      $$$$$$\\            
$$ |                  $$ |    $$ ___$$\\           
$$$$$$$\\  $$\\   $$\\ $$$$$$\\   \\_/   $$ | $$$$$$\\  
$$  __$$\\ $$ |  $$ |\\_$$  _|    $$$$$ / $$  __$$\\ 
$$ |  $$ |$$ |  $$ |  $$ |      \\___$$\\ $$ |  \\__|
$$ |  $$ |$$ |  $$ |  $$ |$$\\ $$\\   $$ |$$ |      
$$$$$$$  |\\$$$$$$$ |  \\$$$$  |\\$$$$$$  |$$ |      
\\_______/  \\____$$ |   \\____/  \\______/ \\__|      
          $$\\   $$ |                              
          \\$$$$$$  |                              
           \\______/                               
            

        `}
        </pre>
        </div>
    )
}
