// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import { React, useContext, useState } from "react";
import { css, jsx } from "@emotion/core";
import { FaSun, FaMoon } from "react-icons/fa";

import { ThemeContext, themes } from "./contexts/ThemeContext";
import Logo from "./Logo";
import Section from "./Section";
import BigText from "./BigText";
import Links from "./Links";

const ThemeButton = ({ callback }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <span onClick={callback}>
      {theme !== themes.light ? <FaSun /> : <FaMoon />}
    </span>
  );
};

export default function MainContainer() {
  const [themeState, setThemeState] = useState(themes.dark);

  const toggleTheme = () => {
    if (themeState === themes.dark) {
      setThemeState(themes.light);
    } else {
      setThemeState(themes.dark);
    }
  };

  const pageStyle = css`
    display: flex;
    justify-content: center;
    background-color: ${themeState.background};
    color: ${themeState.foreground};
    height: 100vh;
    width: 100vw;
    font-family: "Fira Mono";
  `;

  const containerStyle = css`
    width: 55%;
    text-align: center;
  `;

  return (
    <ThemeContext.Provider value={{ theme: themeState }}>
      <div css={pageStyle}>
        <div css={containerStyle}>
          <Logo />
          <BigText />
          <Links />
          <ThemeButton callback={toggleTheme} />
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

