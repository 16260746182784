/** @jsx jsx */
import React from "react";
import Typewriter from "typewriter-effect";
import { css, jsx } from "@emotion/core";

const stuffIDo = [
    "Web Development",
    "Security Research",
    "cool stuff."
]

const BigText = () => {
  return (
    <h1 css={css`
        text-align: left; 
        font-size: 5vh;
        min-height: 30vh;
    `}>
        <Typewriter
            onInit={(typewriter) => {
            typewriter
                .typeString("Yo! ")
                .pauseFor(500)
                .typeString("Im byt3r,<br>")
                .pauseFor(500)
                .typeString("I do ")
                .start();
                for (const stuff of stuffIDo) {
                    typewriter.typeString(stuff)
                    .pauseFor(2500);
                    if (stuffIDo.indexOf(stuff) !== stuffIDo.length-1) {
                        typewriter.deleteChars(stuff.length)
                    }
                }
            }}
            options={{
                delay: 50,
                deleteSpeed: 10,
            }}
        />
    </h1>
  );
};

export default BigText;
