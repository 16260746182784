/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { FaGithub, FaYoutube, FaSteam } from "react-icons/fa"

const Link = ({icon, title, link}) => (
  <a css={css`display: block; text-decoration: none; color: inherit;`} href={link}>
    <span css={css`font-size: 2rem;`}>{icon}</span>
    <br />
    <p css={css`color: gray;`}>{title}</p>
  </a>
)

export default () => {
  return (
    <div css={css`
      display: flex;
      justify-content: space-around;
      margin: 5rem 0 2rem 0;
      `}
    >
      <Link icon={(<FaGithub />)} title="Github" link="https://github.com/BunZe" />
      <Link icon={(<FaSteam />)} title="Steam" link="https://steamcommunity.com/id/BunZze" />
      <Link icon={(<FaYoutube />)} title="Youtube" link="https://www.youtube.com/watch?v=dQw4w9WgXcQ" />
    </div>
  )
}
