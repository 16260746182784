import { createContext } from "react";
export const themes = {
    light: {
      foreground: "#000000",
      background: "#eeeeee",
      special: "lightgray",
    },
    dark: {
      foreground: "#ffffff",
      background: "#000000",
      special: "#171717",
    }
  };
  
export const ThemeContext = createContext({theme: themes.dark});